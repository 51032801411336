import React, { PropsWithChildren } from 'react';
import Footer from '../Footer';
import Header from '../Header';
import Main from '../Main';

type Props = {};

const Layout = (props: PropsWithChildren<Props>) => {
  const { children } = props;

  return (
    <div className="layout">
      {/** @ts-expect-error */}
      <Header data={children.props.menuData} />
      <Main>{children}</Main>
      {/** @ts-expect-error */}
      <Footer data={children.props.footerData} />
    </div>
  );
};

export default Layout;
