import React from 'react';
import Link from 'next/link';
import Logo from '@images/logo.svg';
import Navigation from '@components/Navigation';
import type { WagtailMainMenu } from '@/types';

type Props = {
  data: WagtailMainMenu;
};

const Header = (props: Props) => {
  const { data } = props;

  return (
    <header className="header">
      <Link href="/" passHref>
        <a className="header__link" aria-label="Link auf die Startseite">
          <Logo className="header__logo" />
        </a>
      </Link>
      <Navigation data={data} />
    </header>
  );
};

export default Header;
