import Link from 'next/link';
import type { WagtailMainMenu } from '@/types';
import React, { useRef } from 'react';
import { removeBaseUrl } from '@utils/url';
import Hamburger from '@components/Hamburger';

type Props = {
  data: WagtailMainMenu;
};

const Navigation = (props: Props) => {
  const { data } = props;

  const navRef = useRef<HTMLElement | null>(null);

  if (!data?.items?.length) return null;

  return (
    <>
      <nav
        className="nav"
        id="main-nav"
        aria-label="Hauptnavigation"
        ref={navRef}
      >
        <ul role="list" className="nav__list">
          {data.items.map((item, index) => (
            <li className="nav__list-item" key={`${index}${item.page.id}`}>
              <Link href={removeBaseUrl(item.href)} passHref>
                <a
                  className="nav__link"
                  onClick={() => navRef.current?.classList.remove('nav--open')}
                >
                  {item.text ?? item.page.title}
                </a>
              </Link>
              {item.children.length ? (
                <ul className="nav__sub-menu" role="list">
                  {item.children.map((child, index) => (
                    <li
                      className="nav__sub-menu-item"
                      key={`${index}${item.page.id}`}
                    >
                      {child.text ?? child.page.title}
                    </li>
                  ))}
                </ul>
              ) : null}
            </li>
          ))}
        </ul>
      </nav>
      <Hamburger
        onClick={() => {
          navRef?.current?.classList.toggle('nav--open');
        }}
        aria-label="Navigation umschalten"
        aria-controls="main-nav"
      />
    </>
  );
};

export default Navigation;
